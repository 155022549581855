import Vue from 'vue'
import VueRouter from 'vue-router'

import { checkLogin } from '@/api/auth'
import { getStore } from '@/utils/storage'
import vueThis from '@/main'

const Index = () => import('@/page/index.vue')
const Login = () => import('@/page/Login/login.vue')
const Register = () => import('@/page/Login/register.vue')
const Home = () => import('@/page/Home/home.vue')
const GoodS = () => import('@/page/Goods/goods.vue')
const goodsDetails = () => import('@/page/Goods/goodsDetails.vue')
const Cart = () => import('@/page/Cart/cart.vue')
const order = () => import('@/page/Order/order.vue')
const user = () => import('@/page/User/user.vue')
const orderList = () => import('@/page/User/children/order.vue')
const information = () => import('@/page/User/children/information.vue')
const userInfoUpdate = () => import('@/page/User/children/userInfoUpdate.vue')
const addressList = () => import('@/page/User/children/addressList.vue')
const coupon = () => import('@/page/User/children/coupon.vue')
const aihuishou = () => import('@/page/User/children/aihuishou.vue')
const support = () => import('@/page/User/children/support.vue')
const checkout = () => import('@/page/Checkout/checkout.vue')
const payment = () => import('@/page/Order/payment.vue')
const paysuccess = () => import('@/page/Order/paysuccess.vue')
const Thanks = () => import('@/page/Thanks/thanks.vue')
const Search = () => import('@/page/Search/search.vue')
const RefreshSearch = () => import('@/page/Refresh/refreshsearch.vue')
const RefreshGoods = () => import('@/page/Refresh/refreshgoods.vue')
const orderDetail = () => import('@/page/User/children/orderDetail.vue')
const orderAfterSale = () => import('@/page/User/children/orderAfterSale.vue')
const orderAfterSaleApply = () => import('@/page/User/children/orderAfterSaleApply.vue')
const Alipay = () => import('@/page/Order/alipay.vue')
const Wechat = () => import('@/page/Order/wechat.vue')
const QQpay = () => import('@/page/Order/qqpay.vue')
const submitSuccess = () => import('@/page/Order/submitSuccess.vue')

Vue.use(VueRouter)


const routes = [
  {
    path: '/',
    component: Index,
    name: 'index',
    redirect: '/home',
    children: [
      {path: 'home', component: Home},
      {path: 'goods', component: GoodS},
      {path: 'goodsDetails', name: 'goodsDetails', component: goodsDetails},
      {path: 'thanks', name: 'thanks', component: Thanks},
      {path: '/refreshgoods', name: 'refreshgoods', component: RefreshGoods}
    ]
  },
  {path: '/login', name: 'login', component: Login},
  {path: '/register', name: 'register', component: Register},
  {path: '/cart', name: 'cart', component: Cart},
  {path: '/refreshsearch', name: 'refreshsearch', component: RefreshSearch},
  {
    path: '/order',
    name: 'order',
    component: order,
    children: [
      {path: 'paysuccess', name: 'paysuccess', component: paysuccess},
      {path: 'payment', name: 'payment', component: payment},
      {path: '/search', name: 'search', component: Search},
      {path: 'alipay', name: 'alipay', component: Alipay},
      {path: 'wechat', name: 'wechat', component: Wechat},
      {path: 'qqpay', name: 'qqpay', component: QQpay},
      {path: 'submitsuccess', name: 'submitsuccess', component: submitSuccess},
    ]
  },
  {
    path: '/user',
    name: 'user',
    component: user,
    redirect: '/user/orderList',
    children: [
      {path: 'orderList', name: '訂單列表', component: orderList},
      {path: 'orderDetail', name: '訂單詳情', component: orderDetail},
      {path: 'orderAfterSale', name: '訂單售後', component: orderAfterSale},
      {path: 'orderAfterSaleApply', name: '售後申請', component: orderAfterSaleApply},
      {path: 'information', name: '賬戶資料', component: information},
      {path: 'userInfoUpdate', name: '修改用戶信息', component: userInfoUpdate},
      {path: 'addressList', name: '收貨地址', component: addressList},
      {path: 'coupon', name: '我的優惠', component: coupon},
      {path: 'support', name: '售後服務', component: support},
      {path: 'aihuishou', name: '以舊換新', component: aihuishou}
    ]
  },
  {path: '/checkout', name: 'checkout', component: checkout},
  {path: '*', redirect: '/home'}
]


const router = new VueRouter({
  routes
})


// 不需要登陸的頁面
const whiteList = ['/home', '/goods', '/login', '/register', '/goodsDetails', '/thanks', '/search', '/refreshsearch', '/refreshgoods']
// 掛載路由導航守衛 
// to 將要訪問的路徑；from 代表從哪個路徑跳轉而來；next 是壹個函數，表示放行，next() 放行，next('/login')  強制跳轉
router.beforeEach(function (to, from, next) {
  let params = {
    token: getStore('token')
  }

  // // 白名單放行
  // if (whiteList.indexOf(to.path) !== -1) {
  //   return next()
  // }
  // checkLogin(params).then(res => {
  //   // 校驗登錄通過，放行
  //   if(res.code == 200) {
  //     // 保存用戶信息
  //     vueThis.$store.commit('RECORD_USERINFO', {info: res.data})
  //     // token 還有效的話可以不用登錄了，直接跳轉到主頁
  //     if (to.path === '/login') {
  //       next({path: '/'})
  //     }
  //     return next()
  //   }
  //   // 校驗登錄不通過，跳轉到登錄頁
  //   next('/login')
  // }).catch(err => {
  //   console.info(err)
  //   console.error("請求失敗，停止服務")
  // })

  // 檢查token是否有效
  checkLogin(params).then(res => {
    if (res.code !== 200) { // 沒登錄
      if (whiteList.indexOf(to.path) !== -1) { // 白名單
        next()
      } else {
        next('/login')
      }
    } else {
      // 保存用戶信息
      vueThis.$store.commit('RECORD_USERINFO', {info: res.data})
      if (to.path === '/login') { //  跳轉到
        vueThis.$message.info("您已經登錄，如需重新登錄請前往個人中心退出！")
        next({path: '/'})
      }
      next()
    }
  }).catch(err => {
    console.error(err)
  })

})


export default router