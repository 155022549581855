import Vue from 'vue'
import { 
  Button, 
  Pagination, 
  Checkbox, 
  Icon, 
  Autocomplete, 
  Loading, 
  Message, 
  Notification, 
  Steps, 
  Step, 
  Table, 
  TableColumn, 
  Input, 
  Dialog, 
  Select, 
  Option,
  Cascader,
  Radio,
  RadioGroup,
  Image,
  Form,
  FormItem,
  Switch,
  MessageBox,
  Tooltip,
  Link,
  Result,
  Tabs,
  TabPane,
  Descriptions,
  DescriptionsItem,
  Tag,
  Card,
  Upload,
  DatePicker
} from 'element-ui'

Vue.use(Button)
Vue.use(Pagination)
Vue.use(Checkbox)
Vue.use(Icon)
Vue.use(Autocomplete)
Vue.use(Steps)
Vue.use(Step)
Vue.use(Table)
Vue.use(TableColumn)
Vue.use(Input)
Vue.use(Dialog)
Vue.use(Select)
Vue.use(Option)
Vue.use(Loading.directive)
Vue.use(Cascader)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(Image)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Switch)
Vue.use(Tooltip)
Vue.use(Link)
Vue.use(Result)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Descriptions)
Vue.use(DescriptionsItem)
Vue.use(Tag)
Vue.use(Card)
Vue.use(Upload)
Vue.use(DatePicker)

Vue.prototype.$loading = Loading.service
Vue.prototype.$notify = Notification
Vue.prototype.$message = Message
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt