import request from '@/api/request'

// 檢查登錄
export function checkLogin (params) {
    return request({
        url: '/web/checkLogin',
        method: 'get',
        params
    })
}

// 登錄
export function userLogin (data) {
    return request({
        url: '/web/login',
        method: 'post',
        data
    })
}

// 註冊
export function userRegister (data) {
    return request({
        url: '/web/register',
        method: 'post',
        data
    })
}

// 退出登錄
export function loginOut (params) {
    return request({
        url: '/web/loginOut',
        method: 'get',
        params
    })
}