import Vue from 'vue'
import Vuex from 'vuex'
import mutations from './mutations'
import action from './action'
Vue.use(Vuex)

const state = {
  login: false,   // 是否登錄
  userInfo: null, // 用戶信息
  cartList: [],   // 加入購物車列表
  showMoveImg: false, // 顯示飛入圖片
  elLeft: 0,
  elTop: 0,
  moveImgUrl: null,
  cartPositionT: 0, // 購物車位置
  cartPositionL: 0,
  receiveInCart: false, // 是否進入購物車
  showCart: false // 是否顯示購物車
}

export default new Vuex.Store({
  state,
  action,
  mutations
})
