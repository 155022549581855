import Vue from 'vue'
import App from './App'
// 路由
import router from './router'
// vuex
import store from './store/'
// element-ui 組件
import './plugins/element'
// element-ui 樣式
import 'element-ui/lib/theme-chalk/index.css'
// 延遲加載圖像的 Vue 模塊
import VueLazyload from 'vue-lazyload'
// vue-infinite-scroll 是 vue.js 的無限滾動指令
import infiniteScroll from 'vue-infinite-scroll'
// 用於操作 cookie 的 Vue.js 插件
import VueCookie from 'vue-cookie'
// 內容占位，Vue插件用於在獲取數據時渲染虛假內容，以提供更好的用戶體驗和更低的跳出率
import VueContentPlaceholders from 'vue-content-placeholders'
// 引入公共的常量、函數
import common from '@/utils/common.js'

// import 'amfe-flexible';

// vue裁剪組件vue-cropper
import VueCropper from 'vue-cropper'
Vue.use(VueCropper)

Vue.use(VueContentPlaceholders)
Vue.use(infiniteScroll)
Vue.use(VueCookie)
const loadImage = require('/static/images/load.gif')
Vue.use(VueLazyload, {
  // preLoad: 1.3,
  // error: 'dist/error.png',
  // loading: '/static/images/load.gif'
  loading: loadImage
  // attempt: 1
})

Vue.config.productionTip = false

// 綁定公共的常量、函數到原型上，可以使用 this.$common 引用;例如 this.$common.con1
Vue.prototype.$common = common

/* eslint-disable no-new */
let vueThis = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// 把當前vue實例（this）暴露出去，引用該main.js文件就能拿到當前vue實例（this）
export default vueThis