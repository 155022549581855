// 公共的常量、函數

// 常量，通過對象類型獲取
const constant = {
    // 登錄 token 鍵
    token: "token",
    // 記住用戶名密碼標誌鍵
    remember: "remember",
    // 記住的用戶名鍵
    rusername: "rusername",
    // 記住的密碼鍵
    rpassword: "rpassword",
    // 登錄的用戶信息鍵
    userInfo: "userInfo",
    // 登錄的用戶id鍵
    userId: "userId",
    // header中輸入的搜索key的鍵
    headerSearchKey: "headerSearchKey"
    // 購物車鍵 buyCart
}

// 定義常量示例，直接獲取
const con1 = "常量1"
const arr1 = []
const object1 = {}

// 定義函數示例
var fun = function(a, b, _this) {
    console.info(_this)
	return a
}

// 需要把定義的變量或函數暴露出去
const common = {
    constant,
    con1,
    arr1,
    object1,
    fun
}

// 暴露出去
export default common