import axios from 'axios'
import { Message } from 'element-ui'
import Vue from 'vue'
import { getStore, removeStore } from '@/utils/storage'
import common from '@/utils/common.js'
// import { getToken } from '@/utils/auth'
// 進度條
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
// Vue.prototype.$nprogress = NProgress



// axios配置參看：http://www.axios-js.com/zh-cn/docs/

// 全局配置
// axios.defaults.baseURL = 'https://api.example.com';
// axios.defaults.timeout = 10000
// // axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www=form-urlencoded'
// var baseUrl = process.env.NODE_ENV === 'development' ? '/api/v1' : 'https://api.qunxunyun.com/api/v1'
// axios.defaults.baseURL = baseUrl


// 使用自定義配置新建壹個 axios 實例
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  // baseURL: 'http://43.139.207.2/serve_api',
  // baseURL: 'http://yy.pq886.com:19999' + process.env.VUE_APP_BASE_API, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  // timeout: 5000 // request timeout
  timeout: 50000 // request timeout
})

// 添加請求攔截器
service.interceptors.request.use(
  config => {
    // 在發送請求之前做些什麽

    // 展示進度條
    // NProgress.start()
    const token = getStore('token')
    // console.log('token為',token);
    if (token) {
      // console.log('token為',token);
      // let each request carry token
      // ['X-Token'] is a custom headers key
      // please modify it according to the actual situation
      config.headers.Authorization = 'Bearer ' + token
    }
    return config
  },
  error => {
    // 對請求錯誤做些什麽
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// 添加響應攔截器
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
  */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    // 對響應數據做點什麽
    const res = response.data

    // 隱藏進度條
    // NProgress.done()
    // 令牌失效,或令牌不存在
    const tokenExpiredCode = [306, 307, 308, 309, 310, 311]
    if (tokenExpiredCode.includes(res.code)) {
      console.log('令牌失效,或令牌不存在')
      console.log(common)
      // 清除登錄信息，比如token等，跳轉登錄頁面
      // 清除用戶信息
      removeStore(common.constant.token)
      removeStore(common.constant.userId)
      removeStore(common.constant.userInfo)
      // 移除購物車數據
      removeStore('buyCart')
      location.href = '/#/login'
    }
    // if the custom code is not 20000, it is judged as an error.
    
    // 白名單狀態碼，直接跳過
    const whiteListCode = [314];
    if (res.code !== 200 && !whiteListCode.includes(res.code)) {
      console.log(res)
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
      // return res
      return Promise.reject(new Error(res.message || 'Error'))
    } else {
      return res
    }
  },
  error => {
    console.log('err' + error) // for debug
    Message({
      message: error.message,
      type: 'error',
      duration: 5 * 1000
    })
    return Promise.reject(error)
  }
)

export default service